<template>
    <div class="c-widget--hashtag">
        <post-grid class="c-post-grid--hashtag" :posts="data.posts" :postAmount="$mq === 'small' || $mq === 'medium' ? 2 : 4" >
            <template v-slot:title>
                <h2 class="c-post-grid__title"><router-link :to="`/archive/${ data.archive }`">{{ `#${ data.archive }` }}</router-link></h2>
            </template>
        </post-grid>
    </div>
</template>

<script>
    import PostGrid from '@/components/partials/PostGrid'

    export default {
        name: 'Hashtag',
        props: {
            data: Object
        },

        components: {
            PostGrid
        }
    }
</script>

<style scoped lang="scss" src=""></style>